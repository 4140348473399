.menu-d {
  overflow: hidden !important; }

.navbar {
  padding: 15px;
  height: 130px; }
  @media screen and (max-width: 1088px) {
    .navbar {
      height: 90px; } }

.nav-title {
  font-family: 'Oswald', sans-serif;
  font-size: 1.75em;
  margin-left: 0px; }

.navbar-burger {
  margin-left: initial; }

.navbar-menu p {
  text-align: right; }

.navbar-item .hours {
  display: flex;
  font-weight: 200;
  font-size: 0.72em;
  line-height: 1em;
  text-align: left; }
  .navbar-item .hours .days {
    text-align: left;
    margin-right: 5px; }

.hero-menu {
  position: absolute;
  top: 130px;
  width: 100%;
  left: 0;
  height: 80px;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  transition: 0.7s; }
  .hero-menu a {
    font-weight: 400;
    color: #4a4a4a;
    flex-grow: 1;
    text-align: center;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    transition: 0.25s;
    cursor: pointer; }
    .hero-menu a.hero-active {
      background: white; }
    .hero-menu a:hover {
      background: white; }
    .hero-menu a.hero-active {
      background-color: #FFF; }
  .hero-menu .hero-menu-item {
    flex-grow: 1;
    text-align: center;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    transition: 0.25s;
    cursor: pointer; }
    .hero-menu .hero-menu-item.hero-active {
      background: white; }
    .hero-menu .hero-menu-item:hover {
      background: white; }
    @media screen and (max-width: 1224px) {
      .hero-menu .hero-menu-item {
        font-size: 0.8em;
        width: 100%; } }
  @media screen and (max-width: 1088px) {
    .hero-menu {
      position: absolute;
      z-index: 500;
      top: 78px;
      flex-flow: column;
      height: initial;
      background-color: #fff;
      height: calc(100vh - 78px); }
      .hero-menu.hero-inactive {
        display: none;
        height: 0; }
        .hero-menu.hero-inactive .hero-menu-item {
          height: 0; }
      .hero-menu .hero-menu-item {
        transition: 0.7s;
        min-height: 10vh; }
        .hero-menu .hero-menu-item:hover, .hero-menu .hero-menu-item:active {
          background-color: rgba(152, 152, 152, 0.5); } }

.hero-bg {
  background: url("./img/tryingglasses.jpg") center center;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.3);
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  height: 800px; }
  @media screen and (max-width: 768px) {
    .hero-bg {
      height: 80vh; } }
  .hero-bg .hero-bottom {
    background: #124283;
    background: linear-gradient(0deg, #124283 0%, rgba(108, 171, 226, 0.566264) 100%);
    margin-top: auto;
    width: 100%;
    height: 100px;
    color: white;
    display: flex;
    padding: 20px; }
    @media screen and (max-width: 1088px) {
      .hero-bg .hero-bottom {
        height: 200px !important; } }
    .hero-bg .hero-bottom .hero-left {
      width: 60%;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: space-around; }
      .hero-bg .hero-bottom .hero-left p {
        font-weight: 200;
        font-size: 1.5em;
        line-height: 1em; }
      @media screen and (max-width: 850px) {
        .hero-bg .hero-bottom .hero-left {
          display: none; } }
    .hero-bg .hero-bottom .hero-right {
      width: 40%;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: space-around; }
      @media screen and (max-width: 850px) {
        .hero-bg .hero-bottom .hero-right {
          width: 100%; }
          .hero-bg .hero-bottom .hero-right .hero-address {
            display: block;
            text-align: center; } }

.eye-actions {
  background-color: #F2F0F0;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0; }
  @media screen and (max-width: 1000px) {
    .eye-actions {
      flex-flow: column;
      align-items: center; }
      .eye-actions .eye-action {
        flex-grow: initial;
        width: 100%; } }
  .eye-actions .eye-action {
    padding: 20px;
    height: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    display: flex;
    flex-flow: column;
    text-align: center;
    align-items: center;
    margin-bottom: auto;
    max-height: 300px;
    transition: 0.4s; }
    .eye-actions .eye-action:hover {
      background-color: rgba(198, 197, 197, 0.401); }
      .eye-actions .eye-action:hover .icon {
        transform: scale(1.1);
        padding-bottom: 10px;
        color: #6b8fb9; }
    .eye-actions .eye-action:active .icon {
      transform: scale(0.9);
      padding-bottom: 0px;
      color: #233c5a; }
    .eye-actions .eye-action p {
      font-weight: 300;
      font-size: 1.5em;
      color: #515151; }
      @media screen and (max-width: 850px) {
        .eye-actions .eye-action p {
          font-size: 1em; } }
    .eye-actions .eye-action .icon {
      font-size: 4em;
      height: 1.5em;
      width: 2em;
      color: #4377B2;
      opacity: 0.46;
      -webkit-transition: all 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transition: all 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
      @media screen and (max-width: 850px) {
        .eye-actions .eye-action .icon {
          font-size: 3em;
          width: 1.5em;
          height: 1em;
          margin-top: 15px; } }

.home-content {
  background-color: #FFF;
  padding: 80px;
  display: flex; }
  @media screen and (max-width: 850px) {
    .home-content {
      flex-flow: column;
      padding: 40px; } }
  .home-content .home-left {
    width: 60%; }
    @media screen and (max-width: 850px) {
      .home-content .home-left {
        width: 100%; } }
    .home-content .home-left .home-sub {
      font-size: 1.5em;
      font-weight: 300; }
    .home-content .home-left .home-header {
      font-size: 2em;
      font-weight: 300;
      line-height: 1.2em; }
      .home-content .home-left .home-header .home-title {
        font-family: 'Oswald';
        font-weight: 500; }
  .home-content .home-right {
    width: 40%;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-around; }
    @media screen and (max-width: 850px) {
      .home-content .home-right {
        width: 100%;
        padding: 20px 0;
        height: 300px; } }
    .home-content .home-right iframe {
      max-height: 300px;
      height: 100%;
      width: 100%; }

.eye-services {
  background: url(./img/contacts.jpg) center center;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.7);
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: space-around; }
  .eye-services a {
    color: initial; }
  .eye-services .our-services {
    margin: 30px;
    font-weight: 300;
    font-size: 2em;
    letter-spacing: 0.3em; }
  .eye-services .container {
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 30px; }
    @media screen and (max-width: 900px) {
      .eye-services .container {
        justify-content: space-around; } }
    .eye-services .container .eye-service {
      max-width: 420px;
      width: 30%;
      transition: 0.4s;
      min-height: 200px;
      margin-bottom: 1.5rem;
      background-color: rgba(255, 255, 255, 0.85); }
      @media screen and (max-width: 900px) {
        .eye-services .container .eye-service {
          width: initial; } }
      .eye-services .container .eye-service:hover, .eye-services .container .eye-service:active {
        box-shadow: 0 5px 8px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.3);
        transform: scale(1.015); }
      .eye-services .container .eye-service .service-head {
        font-size: 1.5em;
        font-weight: 200;
        text-align: center; }
      .eye-services .container .eye-service .service-content {
        text-align: justify;
        font-weight: 200;
        font-size: 0.8em; }

.footer {
  width: 100%;
  background-color: #22272B;
  color: #FFFFFF; }
  .footer a {
    color: #fff; }
  .footer .container {
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 768px) {
      .footer .container {
        flex-direction: column-reverse; }
        .footer .container .footer-left {
          text-align: center; }
          .footer .container .footer-left .hours {
            align-items: center;
            justify-content: center;
            margin: 20px; }
        .footer .container .footer-right {
          text-align: center;
          margin-bottom: 30px; } }
  .footer .footer-left .footer-title {
    font-family: 'Oswald', sans-serif;
    font-size: 1.3em; }
  .footer .footer-left .hours {
    font-size: 0.8em;
    line-height: 1.2em;
    display: flex; }
    .footer .footer-left .hours .days {
      margin-right: 10px; }
  .footer .footer-left .copyright {
    margin-top: 40px;
    font-size: 0.7em; }
  .footer .footer-right {
    text-align: right; }
    .footer .footer-right a {
      color: white;
      font-size: 0.9em; }

.content-container .content-image {
  height: 150px;
  background: url("./img/tryingglasses.jpg") center 30%;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.3);
  background-blend-mode: overlay;
  background-repeat: no-repeat; }

.content-container .content-cont {
  padding: 40px; }
  .content-container .content-cont .content-title {
    font-size: 2.5em; }
  .content-container .content-cont .content-html {
    margin-top: 15px;
    margin-right: 25%;
    font-size: 0.9em; }
    .content-container .content-cont .content-html h2 {
      font-weight: 600;
      font-size: 1.2em; }
    .content-container .content-cont .content-html ul {
      list-style: initial;
      padding: 15px;
      margin: 10px; }
    .content-container .content-cont .content-html p {
      margin-bottom: 15px; }
    .content-container .content-cont .content-html img {
      max-width: 400px;
      margin-top: 20px;
      margin-bottom: 20px; }
    @media screen and (max-width: 900px) {
      .content-container .content-cont .content-html {
        line-height: 1.4em;
        text-align: justify;
        margin-right: initial; }
        .content-container .content-cont .content-html img {
          max-width: 100%; } }

.no-match {
  height: 80vh;
  padding: 50px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center; }
  .no-match .no-match-head {
    font-size: 3em; }
  .no-match .no-match-content {
    font-size: 2em; }
